<template>
  <div>
    
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence</th>
          <th scope="col">Nom Complet</th>
          <th scope="col">Total Impaye</th>
          <th scope="col">Total TTC</th>
          <th scope="col">Du</th>
          <th scope="col">Au</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(credit, index) in credits" :key="index++">
          <td>
            {{ index }}
          </td>
          <td>
            {{ credit.customer_reference }}
          </td>
          <td>
            <span v-if="credit.customer">
              {{ credit.customer.fullName }}
            </span>
          </td>
          <td>{{ credit.total }}</td>
          <td>{{ credit.totalTTC }}</td>
          <td>
            <span>
              {{ credit.firstDate }}
            </span>
          </td>
          <td>
            <span>
              {{ credit.lastDate }}
            </span>
          </td>

          <td class="p-0 m-0">
            <button
              @click="
                $router.push({
                  name: 'credits-balances-all',
                  params: { customer_reference: credit.customer_reference },
                })
              "
              class="btn"
            >
              <i class="bi bi-info-square fs-5"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
    };
  },
  computed: {
    ...mapGetters("credit", {
      credits: "getAll",
    }),
  },
  mounted() {
    this.$store.dispatch("credit/getCreditBalance");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("credit/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("credit/getAll");
    },

    async listCredits(checkbox) {
      if (checkbox == true) {
        console.log(checkbox);
        return await this.$store.commit("credit/getTodays");
      } else {
        return await this.$store.dispatch("credit/getCredits");
      }
    },
    async sort(value) {},
  },
};
</script>
